import {
  ABOUT_US_FOOTER_LINK_LIST,
  ABOUT_US_LINK_LIST,
  CAREERS_FOOTER_LINK_LIST,
  CAREERS_LINK_LIST,
  CONSULTING_BUSINESS_SERVICES_LINK_LIST,
  CONTACT_FOOTER_LINK_LIST,
  EXPERTISE_FOOTER_LINK_LIST,
  HOME_FOOTER_LINK_LIST,
  NEWS_FOOTER_LINK_LIST,
  PROJECTS_FOOTER_LINK_LIST,
  SAFETY_FOOTER_LINK_LIST,
} from 'config/routes/linkLists';
import { IFooterLinks, INavLinks, TLabelLinks } from 'containers/layout/types';

const aboutUsLinks: TLabelLinks[] = [
  {
    label: 'About Us',
    links: ABOUT_US_LINK_LIST,
  },
];

const careersLinks: TLabelLinks[] = [
  {
    label: 'Careers',
    links: CAREERS_LINK_LIST,
  },
];

const consultingBusinessServicesLinks: TLabelLinks[] = [
  {
    label: 'Consulting & Business Services',
    links: CONSULTING_BUSINESS_SERVICES_LINK_LIST,
  },
];

const expertiseLinks: TLabelLinks[] = [
  {
    label: 'Expertise',
    links: EXPERTISE_FOOTER_LINK_LIST,
    link: '/expertise',
  },
];

export const routes: INavLinks[] = [
  {
    label: 'Home',
    route: '/',
  },
  {
    label: 'About Us',
    links: aboutUsLinks,
    route: '/about-us',
  },
  {
    label: 'Safety',
    route: '/safety',
  },
  {
    label: 'News',
    route: '/news',
  },
  {
    label: 'Consulting & Business Services',
    links: consultingBusinessServicesLinks,
    route: '',
  },
  {
    label: 'Expertise',
    links: expertiseLinks,
    route: '/expertise',
  },
  {
    label: 'Case Studies',
    route: '/case-studies',
  },
  {
    label: 'Projects',
    route: '/projects',
  },
  {
    label: 'Careers',
    route: '/careers',
    links: careersLinks,
  },
  {
    label: 'Contact Us',
    route: '/contact-us',
  },
];

export const expertiseLinksList: INavLinks[] = [
  {
    label: 'Engineering',
    route: '/expertise/engineering',
  },
  {
    label: 'Construction',
    route: '/expertise/construction',
  },
  {
    label: 'Wireline',
    route: '/expertise/communication/wireline',
    hasAccordion: true,
    mainTitle: 'Communication',
    subtitleHref: '/expertise/communication/wireless',
    subtitle: 'Wireless',
  },
  {
    label: 'Energy',
    route: '/expertise/energy',
  },
  {
    label: 'GPM',
    route: '/expertise/technology-deployment/gpm',
    hasAccordion: true,
    mainTitle: 'Technology Deployment',
    subtitleHref: '/expertise/technology-deployment/noc',
    subtitle: 'NOC',
    secondSubtitleHref: '/expertise/technology-deployment/cyber-security',
    secondSubtitle: 'Cyber Security',
  },
  {
    label: 'Water Utility Services',
    route: '/expertise/water-utility-services',
  },
  {
    label: 'Consulting & Business Services',
    route: '/expertise/consulting-business-services',
  },
  {
    label: 'Fulfillment Services',
    route: '/expertise/fulfillment-services',
  },
];

export const homeFooterLinks: TLabelLinks[] = [
  {
    label: 'Home',
    links: HOME_FOOTER_LINK_LIST,
  },
];

export const aboutUsFooterLinks: TLabelLinks[] = [
  {
    label: 'About Us',
    links: ABOUT_US_FOOTER_LINK_LIST,
  },
];

export const safetyFooterLinks: TLabelLinks[] = [
  {
    label: 'Safety',
    links: SAFETY_FOOTER_LINK_LIST,
  },
];

export const newsFooterLinks: TLabelLinks[] = [
  {
    label: 'News',
    links: NEWS_FOOTER_LINK_LIST,
  },
];

export const consultingBusinessServices: TLabelLinks[] = [
  {
    label: 'Consulting & Business Services',
    links: CONSULTING_BUSINESS_SERVICES_LINK_LIST,
  },
];

export const expertiseFooterLinks: TLabelLinks[] = [
  {
    label: 'Expertise',
    links: EXPERTISE_FOOTER_LINK_LIST,
  },
];

export const projectsFooterLinks: TLabelLinks[] = [
  {
    label: 'Projects',
    links: PROJECTS_FOOTER_LINK_LIST,
  },
];

export const careersFooterLinks: TLabelLinks[] = [
  {
    label: 'Careers',
    links: CAREERS_FOOTER_LINK_LIST,
  },
];

export const contactFooterLinks: TLabelLinks[] = [
  {
    label: 'Contact',
    links: CONTACT_FOOTER_LINK_LIST,
  },
];

export const routesFooter: IFooterLinks[] = [
  {
    label: 'Home',
    route: '/',
    links: homeFooterLinks,
  },
  {
    label: 'About Us',
    links: aboutUsFooterLinks,
    route: '/about-us',
  },
  {
    label: 'Safety',
    links: safetyFooterLinks,
    route: '/safety',
  },
  {
    label: 'News',
    route: '/news',
    links: newsFooterLinks,
  },
  {
    label: 'Consulting & Business Services',
    route: '',
    links: consultingBusinessServices,
  },
  {
    label: 'Expertise',
    route: '/expertise',
    links: expertiseFooterLinks,
  },
  {
    label: 'Case Studies',
    route: '/case-studies',
  },
  {
    label: 'Projects',
    route: '/projects',
    links: projectsFooterLinks,
  },
  {
    label: 'Careers',
    route: '/careers',
    links: careersFooterLinks,
  },
  {
    label: 'Contact',
    route: '/contact-us',
    links: contactFooterLinks,
  },
];
export const privacyPolicyLinks: INavLinks[] = [
  {
    label: '1. Privacy Policy',
    route: '/privacy-policy#privacy',
  },
  {
    label: '2. Consent',
    route: '/privacy-policy#consent',
  },
  {
    label: '3. Information we collect',
    route: '/privacy-policy#informationCollect',
  },
  {
    label: '4. Disclosure of Information',
    route: '/privacy-policy#disclosureOfInformation',
  },
  {
    label: '5. SMS Consent and Phone Numbers',
    route: '/privacy-policy#smsConsentAndPhoneNumbers',
  },
  {
    label: '6. How we use your information',
    route: '/privacy-policy#howUseInformation',
  },
  {
    label: '7. Log Files',
    route: '/privacy-policy#logFiles',
  },
  {
    label: '8. Cookies and Web Beacons',
    route: '/privacy-policy#cookies',
  },
  {
    label: '9. CCPA Privacy Rights (Do Not Sell My Personal Information)',
    route: '/privacy-policy#ccpa',
  },
  {
    label: '10. GDPR Data Protection Rights',
    route: '/privacy-policy#gdpr',
  },
  {
    label: "11. Children's Information",
    route: '/privacy-policy#children',
  },
];
export const termsOfUseLinks: INavLinks[] = [
  {
    label: '1. Terms and conditions',
    route: '/terms-of-use#terms',
  },
  {
    label: '2. Cookies',
    route: '/terms-of-use#cookies',
  },
  {
    label: '3. License',
    route: '/terms-of-use#license',
  },
  {
    label: '4. Hyperlinking to our Content',
    route: '/terms-of-use#hyperlinking',
  },
  {
    label: '5. iFrames',
    route: '/terms-of-use#iFrames',
  },
  {
    label: '6. Content Liability',
    route: '/terms-of-use#liability',
  },
  {
    label: '7. Reservation of Rights',
    route: '/terms-of-use#reservation',
  },
  {
    label: '8. Removal of links from our Website',
    route: '/terms-of-use#removal',
  },
  {
    label: '9. Disclaimer',
    route: '/terms-of-use#disclaimer',
  },
];
