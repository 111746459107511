import React, { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import utilitiesOneTheme from 'config/theme/utilitiesOneTheme';
import 'config/styles/main.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { setENV } from 'config/env';
import { Footer, Navbar } from 'containers/layout';
import { useRouter } from 'next/router';
import I18nProvider from 'blog/providers/I18nProvider';

const MyApp: FC = ({ Component, pageProps }: any) => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');

    if (pageProps.ENV) {
      setENV(pageProps.ENV);
    }

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    AOS.init({
      once: true,
      easing: 'ease-out-quad',
      duration: 500,
    });
  }, []);

  function useNormalScrollRoutes() {
    const router = useRouter();
    useEffect(() => {
      router.events.on('routeChangeStart', () => {
        document.documentElement.classList.add('normal-scroll');
      });
      router.events.on('routeChangeComplete', () => {
        document.documentElement.classList.remove('normal-scroll');
      });
    }, []);
  }

  useNormalScrollRoutes();

  return (
    <>
      <ThemeProvider theme={utilitiesOneTheme}>
        <CssBaseline />
        <I18nProvider dictionary={pageProps.i18n || {}}>
          <article id="main-article">
            <Navbar />
            <main>
              <Component {...pageProps} />
            </main>
            <Footer />
          </article>
        </I18nProvider>
      </ThemeProvider>
    </>
  );
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default MyApp;
