import React, { FC, ReactNode } from "react";
import { makeStyles, Theme, Typography } from '@material-ui/core';
import Link from 'next/link';

interface AccentLinkProps {
  href: string;
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 500,
    color: '#fff',
    marginLeft: theme.spacing(6),
    padding: theme.spacing(1, 2.1),
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    },
  },
}));

const AccentLink: FC<AccentLinkProps> = ({ href, children }) => {
  const classes = useStyles();
  return (
    <Link href={href} passHref>
      <a>
        <Typography className={classes.button}>{children}</Typography>
      </a>
    </Link>
  );
};

export default AccentLink;
