import React, { createContext, FC, ReactNode } from 'react';

export type Dictionary = Record<string, string>;

interface I18nProviderProps {
  children: ReactNode;
  dictionary: Dictionary;
}

export const i18nContext = createContext({});

const Provider = i18nContext.Provider;

const I18nProvider: FC<I18nProviderProps> = ({ children, dictionary }) => {
  return <Provider value={dictionary} children={children} />;
};

export default I18nProvider;
