import React, { FC, useEffect, useState } from 'react';
import { Box, Drawer, Grid, IconButton, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { NextRouter, useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TLinks } from 'containers/layout/types';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CloseIcon, LogoU1 } from 'svgs';
import { routes } from 'config/routes/routes';
import { ArrowDownWithOpacityIcon } from '../../../svgs/consulting-business-services';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiIconButton-root': {
      color: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  burgerMenu: {
    width: 24,
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
  },
  burgerLine: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
    transition: 'background-color 400ms',
    marginBottom: theme.spacing(0.7),
  },
  activeLink: {
    color: theme.palette.common.white,
    fontSize: 24,
  },
  icon: {
    marginTop: -10,
  },
  links: {
    paddingLeft: 12,
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: 500,
    opacity: '20%',
    cursor: 'pointer',
  },
  titleService: {
    color: theme.palette.common.white,
    margin: theme.spacing(2, 0),
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
    opacity: 0.2,
  },
}));

const StyledDrawer = withStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    maxWidth: 331,
    padding: theme.spacing(7, 6),
    backgroundColor: theme.palette.background.paper,
    color: '#ffffff80',
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: theme.spacing(4),
      top: theme.spacing(4),
      cursor: 'pointer',
    },
  },
}))(Drawer);

const MobileNavbar: FC = () => {
  const [open, setOpen] = useState(false);
  const { asPath }: NextRouter = useRouter();
  const classes = useStyles();
  const [openIndexes, setOpenIndexes] = useState<string[]>([]);

  const handleClose = () => {
    setOpen(false);
    setOpenIndexes([]);
  };
  const handleOpen = () => setOpen(true);

  const handleClick = (label: string) => {
    if (openIndexes.includes(label)) {
      setOpenIndexes(openIndexes.filter((i) => i !== label));
    } else {
      setOpenIndexes([...openIndexes, label]);
    }
  };

  useEffect(handleClose, [asPath]);

  return (
    <>
      <Box py={3.5} display="flex" className={classes.root}>
        <IconButton onClick={handleOpen}>
          <BurgerMenu classes={classes} />
        </IconButton>
        <Grid container direction="row" justify="center">
          <Grid item>
            <Link href="/">
              <Box ml={-9}>
                <LogoU1 />
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <StyledDrawer anchor="left" open={open} onClose={handleClose}>
        <CloseIcon onClick={handleClose} />
        {routes.map(({ label, route, links }, index) =>
          label === 'Consulting & Business Services' || label === 'Expertise' ? (
            links.map((item, index) => {
              const isOpen = openIndexes.includes(item.label);
              return (
                <Box key={index} mb={4}>
                  <Box display="flex" onClick={() => handleClick(item.label)} justifyContent="space-between" alignItems="center">
                    <Typography
                      component={item?.link ? 'a' : 'div'}
                      href={item?.link || undefined} // If there's no link, href will be undefined
                      style={{ fontSize: 24, maxWidth: 215 }}
                      className={clsx(isOpen && classes.activeLink)}
                    >
                      {item.label}
                    </Typography>
                    <ArrowDownWithOpacityIcon
                      style={{
                        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease',
                        opacity: isOpen ? '20%' : '100%',
                        position: 'relative',
                        marginTop: -50,
                        marginRight: -25,
                      }}
                    />
                  </Box>

                  {isOpen && (
                    <Box ml={2}>
                      {item.links.map((link) => (
                        <Link key={link.label} href={link.route} passHref>
                          <a>
                            <Typography className={classes.titleService}>{link.label}</Typography>
                          </a>
                        </Link>
                      ))}
                    </Box>
                  )}
                </Box>
              );
            })
          ) : (
            <Box key={index}>
              <NavLink classes={classes} label={label} route={route} active={asPath === route} />
            </Box>
          ),
        )}
      </StyledDrawer>
    </>
  );
};

interface NavLinkProps extends TLinks {
  active: boolean;
  classes: ClassNameMap<string>;
}

const NavLink: FC<NavLinkProps> = ({ active, label, route, classes }) => (
  <Box mb={4}>
    <Link href={route} passHref>
      <Typography component="span" style={{ fontSize: 24 }} className={clsx(active && classes.activeLink)}>
        {label}
      </Typography>
    </Link>
  </Box>
);

const BurgerMenu: FC<{ classes: ClassNameMap<string> }> = ({ classes: { burgerLine, burgerMenu } }) => {
  return (
    <Box className={burgerMenu}>
      <Box className={burgerLine} />
      <Box className={burgerLine} />
      <Box style={{ marginBottom: 0 }} className={burgerLine} />
    </Box>
  );
};

export default MobileNavbar;
