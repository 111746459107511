export interface Environment {
  IS_PRODUCTION: boolean;
  EMAIL_HOST: string;
  EMAIL_PORT: number;
  EMAIL_SECURE: boolean;
  EMAIL_USERNAME: string;
  EMAIL_PASSWORD: string;
  BASE_URL: string;
  CMS_API_BASE_URL: string;
  GOOGLE_MAP_KEY: string;
  RECAPTCHA_SITE_KEY: string;
  GTM_CONTAINER_ID: string;
  GTM_AUTH: string;
  GTM_PREVIEW: string;
  GTM_COOKIES_WIN: string;
}

export let ENV: Environment = {
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
  EMAIL_HOST: process.env.EMAIL_HOST,
  EMAIL_PORT: Number(process.env.EMAIL_PORT),
  EMAIL_SECURE: process.env.EMAIL_SECURE !== 'false',
  EMAIL_USERNAME: process.env.EMAIL_USERNAME,
  EMAIL_PASSWORD: process.env.EMAIL_PASSWORD,
  BASE_URL: process.env.BASE_URL || 'https://utilitiesone.com',
  CMS_API_BASE_URL: process.env.CMS_API_BASE_URL || 'https://admin.energy5.com',
  GOOGLE_MAP_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY,
  RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID,
  GTM_AUTH: process.env.GTM_AUTH,
  GTM_PREVIEW: process.env.GTM_PREVIEW,
  GTM_COOKIES_WIN: process.env.GTM_COOKIES_WIN,
};

export const setENV = (env: Environment) => {
  ENV = { ...env };
};

export const withENV = (pageProps = {}) => ({ ...pageProps, ENV });
