import { createMuiTheme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface GradientNames {
  lightDarkOrange?: string;
  darkOrange?: string;
  blackWhite?: string;
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    gradients?: GradientNames;
  }
  interface PaletteOptions {
    gradients?: GradientNames;
  }
}

const allButtonStyles: CSSProperties = {
  boxShadow: 'none',
  borderRadius: 0,
  textTransform: 'none',
  transition: 'all 200ms',
  '&:hover': {
    transform: 'translate(0)',
    boxShadow: 'none',
  },
};

const utilitiesOneTheme = createMuiTheme({
  spacing: 6,
  palette: {
    gradients: {
      lightDarkOrange: 'linear-gradient(90deg, #FE8155 0%, #E66874 47.85%, #E0809F 100%)',
      darkOrange: 'linear-gradient(90deg, #E86840 0%, #DB5E0D 100%)',
      blackWhite: 'linear-gradient(180deg, #cccccc 0%, #000000 100%);',
    },
    primary: {
      main: '#E0621E',
      light: '#E86840',
      dark: '#292C3D',
      contrastText: '#EBEBED',
    },
    secondary: {
      main: '#90939E',
      light: '#E5E5E5',
      dark: '#7E808F',
      contrastText: '#C3571D',
    },
    text: {
      primary: '#485374',
      secondary: '#4F5260',
      disabled: '#9299A6',
    },
    background: {
      default: '#F5F5F5',
      paper: '#1B1C26',
    },
    common: {
      white: '#fff',
      black: '#000',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif !important',
    h1: {
      fontSize: 45,
      '@media(min-width: 600px)': {
        fontSize: 53,
      },
    },
    h2: {
      fontSize: 32,
      '@media(min-width: 600px)': {
        fontSize: 38,
      },
    },
    h3: {
      fontSize: 24,
      '@media(min-width: 600px)': {
        fontSize: 32,
      },
    },
    h4: {
      fontSize: 26,
    },
    h5: {
      fontSize: 20,
      '@media(min-width: 600px)': {
        fontSize: 23,
      },
    },
    h6: {
      fontSize: 18,
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 13,
    },
  },
  overrides: {
    // @ts-ignore
    MuiContainer: {
      fixed: {
        '@media(min-width: 1920px)': {
          maxWidth: '1380px !important',
        },
      },
      root: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    MuiButton: {
      outlined: allButtonStyles,
      contained: allButtonStyles,
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: '#4F5260',
          opacity: 1,
        },
        color: '#4F5260',
      },
    },
  },
});
export default utilitiesOneTheme;
