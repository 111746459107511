import { ReactComponent as ArrowUpSvg } from './arrow-up.svg';
import { ReactComponent as ArrowDownSvg } from './arrow-down.svg';
import { ReactComponent as ArrowDownWithOpacitySvg } from './arrow-down-opacity-20.svg';
import { ReactComponent as PlusSvg } from './plus.svg';
import { SvgIconProps } from '@material-ui/core';
import { FC, forwardRef } from 'react';

export const ArrowUpIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowUpSvg ref={ref} {...props} />);
export const ArrowDownIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowDownSvg ref={ref} {...props} />);
export const PlusIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PlusSvg ref={ref} {...props} />);
export const ArrowDownWithOpacityIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowDownWithOpacitySvg ref={ref} {...props} />);
